import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'
import teacher from './modules/teacher'
import student from './modules/student'
import remark from './modules/remark'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: () => ({
    appName: '', // app name
    uid: '', // 表单端user.id
    user: {}, // 课堂端用户对象
    class_id: '',
    session: {name: ''},
    classes: {},
    takeaway: {},
    countDown: {time: null, status: 0, start: null, end: null},
    fullDoc: {url: null},
    fullIframe: {url: null},
    slides: [],
    userList: {},
    materials: [],
    materialSelect: [],
    materialMaskId: '',
    syncUI: {
      // new sync ui
      drawUrl: '', // teacher draw background image/color
      drawShow: true,
      fullPlayUrl: '',
      fullPlayPaused: true,
      fullPlayCurrentTime: 0,
      fullPlayDuration: 0,
      fullPlayVolume: 0,
    },
    elements: [], // new class all elements
  }),
  getters: {
    currentMode(state: any) {
      return state.session.status
    },
    getPageId(state: any, getters: any) {
      if (!state.session.pages) return null
      return state.session.pages[getters['student/currentPageIndex']]?._id
    },
    getPage(state: any, {getPageId}: any) {
      if (!state.session.pages) return {}
      return state.session.pages.find((o: any) => o._id === getPageId) || {_id: 0}
    },
    getPageById: (state: any) => (id: string) => {
      if (!state.session.pages) return {}
      return state.session.pages.find((o: any) => o._id === id) || {_id: 0}
    },
    getPageNum(state: any) {
      return state.session?.pages?.length || 0
    },
    getQuestion(state: any, {getPageId}: any) {
      if (!state.session.questions) return null
      return state.session.questions.find((o: any) => o.page === getPageId)
    },
    getMaterial(state: any, {getPageId}: any) {
      const one = state.materials.find((o: any) => o.page === getPageId)
      return one?.list
    },
    getMaterialId(state: any, {getPageId}: any) {
      const one = state.materials.find((o: any) => o.page === getPageId)
      return one?._id
    },
    isStudentPaced(state: any) {
      return state.session.status === 'student'
    },
    isMentorOrSubstitute(state: any) {
      return ['bookingTask', 'bookingPdTask', 'bookingStuTask', 'bookingStuPdTask'].includes(state.session.type)
    },
    isMaterialShow: (state: any, getters: any, rootState: any) => {
      return rootState.teacher.showMaterialMap[rootState.student.currentPageIndex] ?? false
    },
  },
  actions: {
    async initPageConf({commit, state}: any, isStudent: Boolean) {
      // 获取页面配置
      let rs = await App.service('auth').get('pageConf', {query: {sid: state.session.sid}})
      for (const key in rs) {
        //if (key.includes('.')) continue
        if (isStudent && key === 'setCountDown' && state.session.countdown?.studentCtrl) continue
        commit(key.split('.')[0], rs[key])
      }
      // 获取页面配置
      rs = await App.service('auth').get('syncUI', {query: {sid: state.session.sid}})
      commit('setSyncUI', rs)
    },
    setMaterialMaskId({commit, dispatch, state}: any, id: string) {
      commit('setMaterialMaskId', id)
      dispatch('syncTeacher', {commit: 'setMaterialMaskId', to: 'teacher', data: state.materialMaskId})
    },
    upMaterialSelect({commit, dispatch, state}: any, id: string) {
      commit('upMaterialSelect', id)
      dispatch('syncTeacher', {commit: 'setMaterialSelect', to: 'teacher', data: state.materialSelect})
    },
    async initMaterials({commit, state}: any) {
      if (!state.session.id) return
      //const rs = await App.service('materials').get('list', {query: {id: state.session.id}})
      //commit('setMaterials', state.session?.task?.materials ?? [])
      commit('setMaterials', state.session?.materials ?? [])
    },
    async delElement({commit, rootGetters}: any, _id: any) {
      const rs = await App.service('materials').patch(rootGetters.getMaterialId, {$pull: {list: {_id}}})
      commit('setMaterial', rs)
    },
    async addElement({state, rootGetters}: any, data: any) {
      const page_id = rootGetters.getPageId
      // this.commit('addElement', rs)
    },
    async syncData({state}: any, data: any) {
      await App.service('auth').patch('syncData', data)
    },
    async syncTeacher({state}: any, data: any) {
      if (state.session.status === 'student') return
      await App.service('auth').patch('syncTeacher', data)
    },
    async upSession({commit, state}: any, data: any) {
      const rs = await App.service('session').patch(state.session._id, data)
      commit('patchSession', rs)
    },
    async getClassInfo({commit, state}: any) {
      // 获取会话设置
      const session = await App.service('session').get(state.class_id)
      if (!session) return
      commit('setSession', session)
      return session
    },
    async getClassDetail({commit, state}: any) {
      console.log(state, '<===============getClassDetail')
      if (!state.session.classId) return
      const classes = await App.service('classes').get(state.session.classId)
      if (!classes) return
      commit('setClasses', classes)
      return classes
    },
    async getTakeaway({commit, state}: any) {
      let takeaway
      try {
        takeaway = await App.service('session-snapshot').get(state.session._id)
      } catch (e) {}

      if (!takeaway) return
      commit('setTakeaway', takeaway)
      return takeaway
    },
    async setCountDown({commit}: any, data: any) {
      commit('setCountDown', data)
      await App.service('auth').patch('syncTeacher', {commit: 'setCountDown', data})
    },
    async upFullDoc({commit}: any, data: any) {
      await App.service('auth').patch('syncData', {commit: 'upFullDoc', to: 'teacher', data})
      commit('upFullDoc', data)
    },
    async upFullIframe({commit}: any, data: any) {
      await App.service('auth').patch('syncData', {commit: 'upFullIframe', to: 'teacher', data})
      commit('upFullIframe', data)
    },
    async userInfo({commit, state}: any, uid: any) {
      if (state.userList[uid]) return state.userList[uid]
      const rs = await App.service('users').get('userInfo', {query: {uid}})
      commit('upUserList', rs)
      return rs
    },
    async setSyncUI({commit, state}: any, o: any) {
      if (state.session.status === 'live') await App.service('auth').patch('syncUI', o)
      commit('setSyncUI', o)
    },
    async fullPlayClose({dispatch}: any) {
      dispatch('setSyncUI', {fullPlayUrl: null, fullPlayPaused: true, fullPlayVolume: 0})
    },
    async fullPlay({dispatch}: any, o: any) {
      dispatch('setSyncUI', {fullPlayPaused: false, fullPlayVolume: 0, ...o})
    },
  },
  mutations: {
    setSyncUI(state: any, o: any) {
      Object.keys(o).map((key) => {
        Vue.set(state.syncUI, key, o[key])
      })
    },
    async fullPlayClose(state: any, o: any) {
      this.commit('setSyncUI', {fullPlayPaused: true, fullPlayVolume: 0, fullPlayOn: '', fullPlayUrl: null})
    },
    async localPlay(state: any, o: any) {
      this.commit('setSyncUI', {fullPlayPaused: false, fullPlayVolume: 0, fullPlayOn: '', ...o})
    },
    setUser(state: any, one: any) {
      state.user = one
    },
    upUserList(state: any, rs: any) {
      state.userList[rs._id] = rs
    },
    upUserListAll(state: any, arr: any) {
      arr.map((rs: any) => {
        state.userList[rs._id] = rs
      })
    },
    delElement(state: any, id: any) {
      state.elements.del({id})
    },
    addElement(state: any, rs: any) {
      state.elements.set(rs)
    },
    setElements(state: any, arr: any) {
      state.elements.setKey('id')
      arr.map((v: any) => state.elements.set(v))
    },
    setMaterialSelect(state: any, arr: any) {
      state.materialSelect = arr
    },
    setMaterialMaskId(state: any, id: any) {
      state.materialMaskId = id
    },
    upMaterialSelect(state: any, id: any) {
      const index = state.materialSelect.indexOf(id)
      if (index === -1) state.materialSelect.push(id)
      else state.materialSelect.splice(index, 1)

      const total = state.materialSelect.length
      if (total > 4) {
        state.materialSelect.splice(0, total - 4)
      }
    },
    setMaterial(state: any, arr: any) {
      state.materials.set(arr)
    },
    setMaterials(state: any, arr: any) {
      state.materials.setKey('_id')
      arr.map((v: any) => state.materials.set(v))
    },
    initData(state: any, data: any) {
      for (const key of Object.keys(data)) state[key] = data[key]
    },
    setUid(state: any, one: string | number) {
      state.uid = one
    },
    patchSession(state: any, one: any) {
      if (one.status === 'insturctor-paced') one.status = 'live'
      const keys = Object.keys(one)
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i]
        if (k == 'task.pages') {
          Vue.set(state.session, 'pages', one[k])
        } else {
          Vue.set(state.session, k, one[k])
        }
      }
      /*
      Object.keys(one).map((k) => {
        Vue.set(state.session, k, one[k])
      })
      */
      if (one.status === 'live') this.commit('fullPlayClose')
    },
    setSession(state: any, one: any) {
      if (one.status === 'insturctor-paced') one.status = 'live'
      state.session = one
      if (one.status === 'live') this.commit('fullPlayClose')
    },
    setClasses(state: any, one: any) {
      state.classes = one
    },
    setTakeaway(state: any, one: any) {
      state.takeaway = one
    },
    setClassId(state: any, one: any) {
      state.class_id = one.id
      state.appName = one.name
      const old = localStorage.getItem('class_id')
      if (old !== state.class_id) {
        localStorage.removeItem('studentLastPage')
        localStorage.setItem('class_id', state.class_id)
      }
      const end = localStorage.getItem(`${state.class_id}:countDownEnd`)
      if (end) {
        state.countDown.end = parseInt(end)
        state.countDown.status = 1
      }
    },
    setCountDown(state: any, one: any) {
      Object.assign(state.countDown, one)
      let end = null
      if (one.status === 1) {
        if (state.countDown.end) return
        const nt = new Date().getTime()
        end = nt + one.time * 60000
        state.countDown.end = end
        localStorage.setItem(`${state.class_id}:countDownEnd`, end.toString())
      } else if (one.status == 2) {
        state.countDown.end = new Date().getTime() - 1000
        localStorage.setItem(`${state.class_id}:countDownEnd`, state.countDown.end.toString())
      } else if (one.status == 0) {
        localStorage.removeItem(`${state.class_id}:countDownEnd`)
        state.countDown.end = null
      }
    },
    upFullDoc(state: any, one: any) {
      Object.assign(state.fullDoc, one)
    },
    upFullIframe(state: any, one: any) {
      Object.assign(state.fullIframe, one)
    },
  },
  modules: {
    teacher,
    student,
    remark,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
