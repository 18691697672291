import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // teacher
    path: '/t/:id',
    name: 't',
    component: () => import('../views/teacherPage.vue'),
  },
  {
    // student
    path: '/s/:id',
    name: 's',
    component: () => import('../views/students.vue'),
  },
  {
    // dashboard (teacher page or student page)
    path: '/d/:id',
    name: 'd',
    component: () => import('../views/teacherPage.vue'),
  },
  {
    path: '/*',
    name: '*',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
