import Vue from 'vue';
// eslint-disable-next-line import/order
import './elementUse'
import App from './App.vue';
import VueDragResize from 'vue-drag-resize'

import PPT from "@/utils/pptConfig";
// @ts-ignore
import lang from 'element-ui/lib/locale/lang/en'
// @ts-ignore
import locale from 'element-ui/lib/locale'
import store from './store'
import router from './router/index';
import videoPlayer from './components/common/videoPlayer.vue'

// 设置语言
locale.use(lang)

Vue.component('vue-drag-resize', VueDragResize)
Vue.component('VideoPlayer', videoPlayer)

// 挂载到$message上
Vue.config.productionTip = false;
window.EventBus = new Vue();
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
Vue.prototype._ = window._
Vue.prototype.Acan = window.Acan
Vue.prototype.Fn = window.Fn
Vue.prototype.urlProxy = (url: string) => {
  return (url?.includes('.google') && PPT.isDev) ? `/fio/tool/wget?url=${encodeURIComponent(url)}` : url
}
setTimeout(() => {
  if (window.VueError) Vue.config.errorHandler = VueError
}, 300)