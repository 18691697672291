<template>
  <div class="flex_c" style="width:100%">
    <video controlslist="nodownload" :controls="control" :src="Fn.hashToUrl(src)" preload="meta" ref="videoPlayer" muted/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'VideoPlayer',
  props: {
    controls: { type: Boolean, default: false },
    src: { type: String, default: '' },
    id: { type: Number, default: null }
  },
  computed: {
    ...mapState(['syncUI']),
    control() {
      return this.syncUI.fullPlayUrl === this.src ? false : this.controls
    }
  },
}
</script>
