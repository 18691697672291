interface PPT {
  isDev: boolean,
  isTest: boolean,
  formWebUrl: string, // 表单页面地址
}
const PPT: PPT = {
  isDev: false,
  isTest: false,
  formWebUrl: '',
}
const isDev = /(dev|localhost|127\.0\.0\.1|192\.168\.|10\.0\.)/.test(location.hostname)
PPT.isDev = isDev
PPT.isTest = location.hostname.includes('test.') 
PPT.formWebUrl = PPT.isTest ? 'https://testroom.classcipe.com/' : isDev ? 'https://dev.classcipe.com/' : 'https://classcipe.com/'

export default PPT
