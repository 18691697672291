<template>
  <div id="app">
    <router-view />
    <!--
    <feedback />
    -->
  </div>
</template>

<style lang="scss">
@import url(./assets/css/common.scss);
@import url(./assets/css/modal.scss);
@import url(./assets/css/dashheader.scss);
</style>
<script>
import "./utils/pptConfig";
//import Feedback from "./components/feedback/Feedback"

export default {
  components: {
    //Feedback
  },
  mounted() {
    window.winWidth = document.documentElement.clientWidth;
    window.winHeight = document.documentElement.clientHeight;

    window.onblur = function(e){
      // // console.log("未激活状态！ window状态")
      window.isWindowActive = false
    }
    window.onfocus = function(e){
      // // console.log("激活状态！window状态")
      window.isWindowActive = true
    }
    document.body.onmouseleave = function(e){
      // // console.log("未激活状态！ window状态")
      window.isWindowActive = false
    }
    document.body.onpointerenter = function(e){
      // // console.log("激活状态！window状态")
      window.isWindowActive = true
    }
  },
};
</script>
