export enum SocketEventsEnum {
  GO_PAGE = 'GO_PAGE', // 教师端发控制
  ANSWER_QUESTION = 'response', // 回答
  UPDATE_TIP = 'UPDATE_TIP', // 回答
  DRAW_CANVAS = 'draw', // 白板
  TEXT_INPUT = 'text', // 文本框输入
  NUMBER_INPUT = 'number', // 数字输入
  MEDIA_INPUT = 'media',
  CONTROL = "teacher_control", // 教师端发送控制命令
  END_SESSION = 'end_session',
  CHANGE_SESSION_STATUS = 'change_session_status',
  LOCK_PAGE = 'lock_page',
  COPY_LINK_DIALOG_CLOSE = 'copy_link_dialog_close',
  COPY_LINK_DIALOG_OPEN = 'copy_link_dialog_open',
  STUDENT_DELETE_PPT = 'STUDENT_DELETE_PPT',
  TEACHER_ADD_MEDIA = 'add-element',
  EVALUATION_SET_CHANGE = 'evalution-set-change' // 评估表配置变化，同步更新自评、他评按钮显示状态
}

export enum ModalEventsNameEnum {
  SHOW_STUDENT_MODAL = 'SHOW_STUDENT_MODAL', // 打开学生端评论
  SHOW_STUDENT_MODAL_REFRESH = 'SHOW_STUDENT_MODAL_REFRESH', // 更新
  SHOW_STAR_ANSWER = 'SHOW_STAR_ANSWER', // 老师给答案点星星，或者取消星星,或者老师确定隐藏某个答案
  MEDIA_MODAL_VISIBLE = 'MEDIA_MODAL_VISABLE',
  DELETE_MEDIA_ELEMENT = 'DELETE_MEDIA_ELEMENT', // 删除element
}

// 评论类型
export enum ModalEventsTypeEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO'
}
