import Vue from 'vue'

const teachers: any = []
teachers.setKey('_id')
const groups: any = []
groups.setKey('_id')

const state = () => ({
  rooms: {_id: '', sid: '', members: [], groups: [], groupMax: 10, block: [], attend: []},
  onlineList: {}, // 在线 user._id
  onlineTeachers: {}, // user._id
  classStudents: [], // 班级的学生列表 新版
  teachers, // 完整的老师列表 新版
  groups, // 完整的分组 新版
  attendances: {}, // 完整的出勤学生id列表 新版
  pageConf: {tab: null}, // 当前页面设置 新版
  remarkFocusId: null, // remark focus id
  feedBackList: [], // 老师的feedback回答列表
  feedBackAnswerIds: [], // 已feedback的id map
  currentPreviewData: {
    type: '',
    url: '',
  }, // 当前预览的内容。包括图片。pdf。视频
  myGroupId: -1, // 我的组，-1 表示没设置
  showResponseMap: [], // 老师广播 展示答案状态
  showMaterialMap: [], // 老师广播
  lockPageMap: [], // 小组广播
  answerTabMap: [], // 老师广播
  showMoreMap: [], // 无需广播 临时展开答案状态
  commentData: {}, // 回复框数据
})
// getters
const getters = {
  memberNames: (state: any) => {
    const list: any = {}
    for (const o of state.rooms.members) {
      list[o._id] = o.nickname
    }
    for (const o of state.teachers) {
      list[o._id] = o.nickname
    }
    return list
  },
  isResponseShow: (state: any) => (pageId: string) => {
    return state.showResponseMap[pageId] ?? false
  },
  isLocked: (state: any) => (pageId: string) => {
    return state.lockPageMap[pageId] ?? false
  },
  answerTab: (state: any) => (pageId: string) => {
    return state.answerTabMap[pageId] ?? ''
  },
  onlineStudentCount(state: any) {
    let num = 0
    for (const _id in state.onlineList) {
      if (state.onlineList[_id]) num++
    }
    return num
  },
}
// 新版
const mutations2 = {
  setCommentData(state: any, one: any) {
    one.sname = window.currentNickname
    state.commentData = one
  },
  setRemarkFocusId(state: any, one: any) {
    state.remarkFocusId = one
  },
  patchRooms({rooms}: any, {members, teachers, attend, groups, groupMax, block}: any) {
    if (members) {
      /*
      if (Object.keys(members).length === 1) rooms.members.del(members)
      else rooms.members.set(members)
      rooms.members.del(members)
      */
      if (Object.keys(members).length !== 1) {
        Vue.set(rooms, 'members', rooms.members.filter((e) => e.email !== members.email).concat(members))
      }
    }
    if (groups) {
      if (Object.keys(groups).length === 1) rooms.groups.del(groups)
      else rooms.groups.set(groups)
    }
    if (groupMax) Object.assign(rooms, {groupMax})
    if (block) Vue.set(rooms, 'block', block)
    if (attend) Vue.set(rooms, 'attend', attend)
    if (teachers) {
      //Vue.set(rooms, 'teachers', teachers)
      Vue.set(rooms, 'teachers', rooms.teachers.filter((e) => e.email !== teachers.email).concat(teachers))
    }
    // if (!rooms.attend.includes(attend)) rooms.attend.push(attend)
  },
  setRooms({rooms}: any, rs: any) {
    window.roomId = rs._id
    Object.assign(rooms, rs)
    rooms.members.setKey('_id')
    rooms.groups.setKey('_id')
  },
  attendStudent({rooms}: any, _id: any) {
    const i = rooms.attend.indexOf(_id)
    if (i === -1) rooms.attend.push(_id)
    else rooms.attend.splice(i, 1)
  },
  blockStudent({rooms}: any, _id: string) {
    const i = rooms.block.indexOf(_id)
    if (i === -1) rooms.block.push(_id)
    else rooms.block.splice(i, 1)
  },
  setOnlineTeacher(state: any, [arr, flag]: any) {
    arr.map((v: string) => {
      Vue.set(state.onlineTeachers, v, flag)
    })
  },
  setOnlineList(state: any, [arr, flag]: any) {
    arr.map((v: string) => {
      Vue.set(state.onlineList, v, flag)
    })
  },
  setTeacher(state: any, one: any) {
    // 局部更新数据
    const old = state.teachers.get(one._id)
    if (!old) return state.teachers.set(one)
    Object.assign(old, one)
    state.teachers.set(old)
  },
  setTeachers(state: any, arr: any) {
    for (const one of arr) {
      if (!one._id) continue
      console.log('setTeacher:', one.nickname)
      state.teachers.set(one) // 更新数据
    }
  },
  clearTeachers(state: any) {
    state.teachers.length = 0
  },
}
const actions2 = {
  async userInfo({state, dispatch}: any, uid: string) {
    let rs = state.rooms.members.find((v: any) => v._id === uid)
    if (!rs) rs = await dispatch('userInfo', uid, {root: true})
    return Acan.clone(rs)
  },
  async setRemarkFocusId({commit, rootState}: any, data: any) {
    if (rootState.session.status === 'live') App.service('auth').patch('syncTeacher', {commit: 'teacher/setRemarkFocusId', data})
    commit('setRemarkFocusId', data)
  },
  async setGroupMember({commit}: any, [group, _id]: any) {
    // 更新学生分组
    const rs = await App.service('rooms').patch(roomId, {'members.$.group': group}, {query: {'members._id': _id}})
    commit('patchRooms', rs)
  },
  async blockStudent(c: any, _id: string) {
    const {commit, state} = c
    const con = {block: _id}
    const isBlock = state.rooms.block.includes(_id)
    await App.service('rooms').patch(roomId, isBlock ? {$pull: con} : {$addToSet: con})
    commit('blockStudent', _id)
    const m = state.rooms.members.find((v: any) => v._id === _id && !!v.group)
    if (!isBlock && m) {
      console.log(m.group, m, 'remove member group')
      await actions2.setGroupMember(c, ['', _id]) // block的时候移出组
    }
    console.log(isBlock, _id)
  },
  attendStudent({commit, state}: any, _id: any) {
    const con = {attend: _id}
    const isAttend = state.rooms.attend.includes(_id)
    App.service('rooms').patch(roomId, isAttend ? {$pull: con} : {$addToSet: con})
    commit('attendStudent', _id)
  },
  async addGroup({commit}: any, groups: any) {
    const rs = await App.service('rooms').patch(roomId, {$addToSet: {groups}})
    if (!rs._id) return
    commit('patchRooms', {...rs})
  },
  async delGroup({commit}: any, groups: any) {
    const rs = await App.service('rooms').patch(roomId, {$pull: {groups}})
    if (!rs?._id) return
    commit('patchRooms', rs)
  },
  async upGroup({commit}: any, groups: any) {
    const rs = await App.service('rooms').patch(roomId, {'groups.$.name': groups.name}, {query: {'groups._id': groups._id}})
    if (!rs?._id) return
    commit('patchRooms', rs)
  },
  async upGroupMax({commit}: any, groupMax: any) {
    const rs = await App.service('rooms').patch(roomId, {groupMax})
    commit('patchRooms', rs)
  },
  async syncOnlineUsers({commit, rootState}: any) {
    // 同步在线人员
    const rs = await App.service('auth').get('onlineUsers', {query: {sid: rootState.class_id}})
    if (rs?.list) {
      for (const one of rs.list) {
        if (one.role === 'teacher') commit('setTeacher', one), commit('setOnlineTeacher', [[one._id], true])
        else commit('setOnlineList', [[one._id], true])
      }
      console.log(rs?.list, 'syncOnlineUsers')
    }
  },
  async initStudent({commit, rootState}: any, user: any) {
    if (user) commit('setUser', user, {root: true})
    App.io.on('connect', () => {
      console.log('reconnect')
      actions2.syncOnlineUsers({commit, rootState})
    })
    let rs
    rs = await App.service('rooms').get(rootState.class_id)
    const roaster = await App.service('session').get('roaster', {query: {sid: rootState.class_id}})
    roaster.forEach((item) => {
      const exist = rs.members.find((member) => member.email == item.email)
      if (!exist) {
        rs.members.push(item)
      }
    })
    if (rs) {
      commit('setRooms', rs)
      commit('upUserListAll', Acan.clone(rs.members), {root: true})
    }

    rs = await App.service('rooms').patch('joinRoomTeacher', {_id: roomId})
    if (rs) {
      commit('patchRooms', rs)
    }

    App.service('comments').removeAllListeners(['created'])
    App.service('comments').on('created', (rs: any) => {
      commit('addFeedBack', rs)
    })
    App.service('rooms').removeAllListeners(['patched'])
    App.service('rooms').on('patched', (rs: any) => {
      if (rs._id !== roomId) return
      console.log(rs, 'patched')
      if (!rs.__v) {
        commit('patchRooms', rs)
        if (rs.members) commit('upUserList', rs.members, {root: true})
      } else {
        commit('setRooms', rs)
        commit('upUserListAll', Acan.clone(rs.members), {root: true})
      }
    })
    App.service('materials').removeAllListeners(['patched'])
    App.service('materials').on('patched', (rs: any) => {
      commit('setMaterial', rs, {root: true})
    })
    console.log('点名册数据 ok', rs)
    actions2.syncOnlineUsers({commit, rootState})
  },
  showMoreBtn({commit, state}: any, index: string) {
    const status = state.showMoreMap[index] ?? false
    commit('showMoreBtn', [index, !status])
  },
  answerTabBtn({commit, rootState}: any, arr: string) {
    commit('setAnswerTabMap', arr)
    if (rootState.session.status === 'live') App.service('auth').patch('syncTeacher', {commit: 'teacher/setAnswerTabMap', data: arr})
  },
  lockPageBtn({commit, state}: any, index: string) {
    const status = state.lockPageMap[index] ?? false
    const arr = [index, !status]
    commit('setLockPageMapOne', arr)
    App.service('auth').patch('syncTeacher', {commit: 'teacher/setLockPageMap', data: state.lockPageMap})
  },
  showResponse({commit, state, rootState}: any, arr: string[]) {
    const status = state.showResponseMap[arr[0]] ?? false
    const name = !status ? 'showPageResponse' : 'hidePageResponse'
    commit(name, arr)
    if (rootState.session.status === 'live') App.service('auth').patch('syncTeacher', {commit: 'teacher/' + name, data: arr})
  },
  showMaterial({commit, state, rootState}: any, arr: string[]) {
    arr[0] = rootState.student.currentPageIndex
    const status = state.showMaterialMap[rootState.student.currentPageIndex] ?? false
    const name = status ? 'hidePageMaterial' : 'showPageMaterial'
    commit(name, arr)
    if (rootState.session.status === 'live') App.service('auth').patch('syncTeacher', {commit: 'teacher/' + name, data: arr})
  },
}

// actions
const actions = {
  ...actions2,
  setFeedBackList({commit}: any, list: any) {
    commit('setFeedBackList', list)
  },
  addFeedBack({commit}: any, data: any) {
    commit('addFeedBack', JSON.parse(JSON.stringify(data)))
  },
  setFeedBackAnswerIds({commit}: any, listMap: any) {
    commit('setFeedBackAnswerIds', listMap)
  },
  setCurrentPreviewData({dispatch, commit}: any, data: any) {
    commit('setCurrentPreviewData', data)
    if (!data.noSync) dispatch('syncData', {commit: 'teacher/setCurrentPreviewData', data, to: 'teacher'}, {root: true})
  },
  setMyGroupId({commit}: any, id: number) {
    commit('setMyGroupId', id)
  },
}

// mutations
const mutations = {
  ...mutations2,
  setFeedBackList(nextState: any, list: any) {
    nextState.feedBackList = list
  },
  addFeedBack({feedBackList, feedBackAnswerIds}: any, data: any) {
    feedBackList.unshift(data)
    if (!feedBackAnswerIds.includes(data.rid)) feedBackAnswerIds.push(data.rid)
  },
  setFeedBackAnswerIds(nextState: any, listMap: any) {
    nextState.feedBackAnswerIds = listMap
  },
  setCurrentPreviewData(nextState: any, data: any) {
    nextState.currentPreviewData = data || {type: '', url: ''}
  },
  setCurrentAnswerTab(nextState: any, tab: number) {
    nextState.currentAnswerTab = tab
  },
  showMoreBtn(state: any, [index, status]: string) {
    Vue.set(state.showMoreMap, index, status)
  },
  setAnswerTabMap(state: any, [index, tab]: string) {
    Vue.set(state.answerTabMap, index, tab)
  },
  setLockPageMapOne(state: any, arr: any) {
    return Vue.set(state.lockPageMap, arr[0], arr[1])
  },
  setLockPageMap(state: any, arr: any) {
    if (Number.isInteger(arr[0])) return
    arr.map((v: boolean, i: number) => Vue.set(state.lockPageMap, i, v))
  },
  // 页面按钮状态控制
  showPageResponse(state: any, arr: string[]) {
    Vue.set(state.showMoreMap, arr[0], true)
    Vue.set(state.showResponseMap, arr[0], true)
    Vue.set(state.showMaterialMap, arr[0], false)
  },
  hidePageResponse(state: any, arr: string[]) {
    Vue.set(state.showMoreMap, arr[0], false)
    Vue.set(state.showResponseMap, arr[0], false)
  },
  showPageMaterial(state: any, arr: string[]) {
    Vue.set(state.showMaterialMap, arr[0], true)
    Vue.set(state.showMoreMap, arr[0], false)
    Vue.set(state.showResponseMap, arr[0], false)
  },
  hidePageMaterial(nextState: any, arr: string[]) {
    Vue.set(nextState.showMaterialMap, arr[0], false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
