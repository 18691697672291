/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
const groups: any = []
groups.setKey('_id')
const studentFeedBackComments: any = []
studentFeedBackComments.setKey('_id')
const urlData = new URL(location.href)
const class_id = urlData.pathname.split('/').pop()
const state = () => ({
  user: {},
  class_id,
  elements: [],
  rooms: {_id: '', sid: '', members: [], groups: [], groupMax: 10, block: [], attend: []},
  currentPageIndex: 0,
  groups, // 分组列表 新版
  unreadMap: [], // 未读Tips
  answerdPage: {}, // 已回答page
  allAnswerList: [], // 全部回答数据
  studentFeedBackComments, // 老师端评论
  unreadStudentCommentIds: [], // 未读评论
  selectGroupMap: [], // 选择的组
  selectMembersMap: [], // 选择的学生
  isViewGroup: false, // 是否在查看同组学生
})

import Vue from 'vue'
const mutations2 = {
  setUnreadMap(state: any, arr: any) {
    state.unreadMap[arr[0]] = arr[1]
  },
  setUser(state: any, one: any) {
    localStorage.setItem(location.pathname + '-user', JSON.stringify(one))
    state.user = one
    console.log('setUser', one)
  },
  patchRooms({rooms}: any, {members, attend, groups, groupMax, block}: any) {
    if (members) {
      if (Object.keys(members).length === 1) rooms.members.del(members)
      else rooms.members.set(members)
    }
    if (groups) {
      if (Object.keys(groups).length === 1) rooms.groups.del(groups)
      else rooms.groups.set(groups)
    }
    if (groupMax) Object.assign(rooms, {groupMax})
    if (block) Vue.set(rooms, 'block', block)
    if (attend) Vue.set(rooms, 'attend', attend)
  },
  setRooms({rooms}: any, rs: any) {
    window.roomId = rs._id
    Object.assign(rooms, rs)
    rooms.members.setKey('_id')
    rooms.groups.setKey('_id')
  },
}
const actions2 = {
  async userInfo({state, dispatch}: any, uid: string) {
    let rs = state.rooms.members.find((v: any) => v._id === uid)
    if (!rs) rs = await dispatch('userInfo', uid, {root: true})
    return rs
  },
  async joinGroup({commit}: any, group: any) {
    console.log(group, {'members.$.group': group._id}, {query: {'members._id': currentUserId}})
    const rs = await App.service('rooms').patch(roomId, {'members.$.group': group._id}, {query: {'members._id': currentUserId}})
    commit('patchRooms', rs)
  },
  async delResponse({commit}: any, _id: any) {
    // remove response
    await App.service('response').remove(_id)
    commit('deleteOnAnswerById', _id)
  },
  async upSertResponse({commit}: any, one: any) {
    // upsert response
    const rs = one._id ? await App.service('response').patch(one._id, one) : await App.service('response').create(one)
    commit('setAllAnswerdListOne', rs)
    return rs
  },
  setUser({commit}: any, d: any) {
    commit('initData', {user: d}, {root: true})
    commit('setUser', d)
  },
  async initStudent(c: any, user: any) {
    const {commit} = c
    if (user) commit('setUser', user)
    App.service('comments')
      .on('created', (rs: any) => {
        // 收到评论
        if (rs.to !== currentUserId) return console.log('not self comments', rs)
        commit('upStudentComment', rs)
      })
      .on('patched', (rs: any) => {
        if (rs.to !== currentUserId) return console.log('not self comments', rs)
        commit('upStudentComment', rs)
      })

    let rs = await App.service('rooms').get(class_id)
    if (!rs) return console.log('no room data')
    if (rs) {
      commit('setRooms', rs)
      commit('upUserListAll', Acan.clone(rs.members), {root: true})
    }
    rs = await App.service('rooms').patch('joinRoom', {_id: roomId})
    if (rs) {
      commit('patchRooms', rs)
    }
    console.log('joinRoom: ', rs)
    App.service('rooms').on('patched', (rs: any) => {
      if (rs._id !== roomId) return
      if (!rs.__v) {
        commit('patchRooms', rs)
        if (rs.members) commit('upUserList', rs.members, {root: true})
      } else {
        commit('setRooms', rs)
        commit('upUserListAll', Acan.clone(rs.members), {root: true})
      }
    })
  },
}

// getters
const getters = {
  currentTips: (state: any, getters: any) => {
    return {}
    // const tipItemIndex = elements.findIndex((ele: any) => ele.type === 'tip')
    // return elements[tipItemIndex] ?? {}
  },
  groupMembers: (state: any, getters: any) => {
    if (!getters.roomMe?._id) return []
    return state.rooms.members.filter((v: any) => v.group === getters.roomMe.group)
  },
  roomGroup: (state: any, getters: any) => {
    if (!getters.roomMe?.group) return null
    if (!state.rooms?.groups) return null
    return state.rooms.groups.find((v: any) => v._id === getters.roomMe.group)
  },
  roomMe: (state: any) => {
    return state.rooms.members.find((v: any) => v._id === currentUserId)
  },
  currentPageIndex: (state: any) => {
    return state.currentPageIndex
  },
  groupMemberNames: (state: any) => {
    const list: any = {}
    for (const o of state.rooms.members) {
      list[o._id] = o.nickname
    }
    return list
  },
  selectedGroupMembers: (state: any) => {
    return state.selectMembersMap[state.currentPageIndex] || []
  },
  selectedMembers: (state: any) => {
    return state.selectMembersMap[state.currentPageIndex] || []
  },
  selectedGroup: (state: any) => {
    return state.selectGroupMap[state.currentPageIndex] || ''
  },
  groupMemberIds: (state: any, getters: any, rootState: any) => {
    if (!getters.selectedGroup) return []
    const group = rootState.teacher.groups.get(getters.selectedGroup)
    if (!group) return []
    return group.members.map((o: any) => {
      return o.id
    })
  },
  // 本页的全部回答数据
  currentPageAnswerList: ({allAnswerList}: any, getters: any, rootState: any, {getQuestion}: any) => {
    const {type, page} = getQuestion || {}
    return allAnswerList.filter((item: any) => item.page === page && item.type === type)
  },
  myPageAnswerList: ({allAnswerList}: any, getters: any, rootState: any, {getQuestion}: any) => {
    const {type, page} = getQuestion || {}
    const arr = allAnswerList.filter((item: any) => item.page === page && item.type === type && item.uid === currentUserId)
    arr.setKey('_id')
    return arr
  },
  currentFeedList: ({studentFeedBackComments}: any, getters: any, rootState: any, {getPageId}: any) => {
    let list: any = []
    if (!getPageId) return list
    if (rootState.session?.status === 'student') {
      list = studentFeedBackComments
    } else {
      list = studentFeedBackComments.filter((item: any) => item.page === getPageId)
    }
    return list
  },
  setIsViewGroup: (currentState: any, nextStatus: boolean) => {
    currentState.isViewGroup = nextStatus
  },
}

// actions
const actions = {
  ...actions2,
  setIsViewGroup({commit}: any, nextStatus: boolean) {
    commit('setIsViewGroup', nextStatus)
  },
  setElements({commit}: any, list: any) {
    commit('setElements', JSON.parse(JSON.stringify(list)))
  },
  setStudentPageIndex({commit, dispatch}: any, index: number) {
    if (!isNaN(index)) {
      commit('setCurrentPageIndex', index)
      dispatch('fullPlayClose', {}, {root: true})
    }
  },
  updateAnswerdPage({commit}: any, pageIndex: string) {
    commit('updateAnswerdPage', pageIndex)
  },
  setAllAnswerdList({commit}: any, list: any) {
    commit('setAllAnswerdList', list)
  },
  setStudentFeedBackComment({commit}: any, list: any) {
    commit('setStudentFeedBackComment', list)
  },

  delUnreadCommentId({commit}: any, id: any) {
    commit('delUnreadCommentId', id)
  },

  updateAnswerStarOrResponse({commit}: any, data: any) {
    commit('updateAnswerStarOrResponse', data)
    App.service('auth').patch('syncTeacher', {commit: 'student/updateAnswerStarOrResponse', data})
  },
}

// mutations
const mutations = {
  ...mutations2,
  setElements(nextState: any, list: any) {
    nextState.elements = list
  },
  setCurrentPageIndex(nextState: any, index: number) {
    nextState.currentPageIndex = index
  },
  updateAnswerdPage(nextState: any, pageIndex: number) {
    const {answerdPage} = nextState
    answerdPage[pageIndex] = true
    nextState.answerdPage = JSON.parse(JSON.stringify(answerdPage))
  },
  setAllAnswerdList(state: any, list: any) {
    state.allAnswerList = list
  },
  setAllAnswerdListOne(state: any, one: any) {
    const index = state.allAnswerList.findIndex((o: any) => o._id === one._id)
    if (index > -1) Vue.set(state.allAnswerList, index, one)
    else state.allAnswerList.push(one)
  },
  deleteOnAnswerById(state: any, id: any) {
    const index = state.allAnswerList.findIndex((item: any) => item._id === id)
    if (index > -1) state.allAnswerList.splice(index, 1)
  },
  setStudentFeedBackComment(state: any, list: any[]) {
    state.studentFeedBackComments.length = 0
    state.studentFeedBackComments = [...list]
    state.studentFeedBackComments.setKey('_id')

    let unreadComments: any = []
    unreadComments = state.studentFeedBackComments.filter((comment: any) => comment.read != true)
    state.unreadStudentCommentIds = [
      ...unreadComments.map((o: any) => {
        return o._id
      }),
    ]
  },
  upStudentComment(state: any, data: any) {
    const old = state.studentFeedBackComments.get(data._id)
    if (old) return Object.assign(old, data)
    state.studentFeedBackComments.unshift(data)
    state.unreadStudentCommentIds.push(data._id)
  },
  delUnreadCommentId(state: any, id: any) {
    const index = state.unreadStudentCommentIds.indexOf(id)
    if (index > -1) {
      state.unreadStudentCommentIds.splice(index, 1)
    }
  },
  clearSelectGroupMap(state: any, group_id: number) {
    state.selectGroupMap.map((v: number, i: number) => {
      if (v !== group_id) return
      Vue.set(state.selectGroupMap, i, null)
    })
  },
  setSelectGroup(state: any, arr: any) {
    Vue.set(state.selectGroupMap, arr[0], arr[1])
    Vue.set(state.selectMembersMap, arr[0], [])
  },
  setSelectMembers(state: any, arr: any) {
    Vue.set(state.selectMembersMap, arr[0], arr[1])
  },
  toggleSelectMember(state: any, one: string | number) {
    let list = state.selectMembersMap[state.currentPageIndex] || []
    const index = list.indexOf(one)
    if (index > -1) list.splice(index, 1)
    else list.push(one)
    Vue.set(state.selectMembersMap, state.currentPageIndex, list)
  },
  updateAnswerStarOrResponse(nextState: any, data: any) {
    const item = nextState.allAnswerList.find((item: any) => item._id === data._id)
    if (item)
      Object.keys(data).map((key) => {
        if (key === '_id') return
        Vue.set(item, key, data[key])
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
