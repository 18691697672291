
// remarks
export const RemarkActionTypes = {
  SHOW_REMARK_MODAL: 'showRemarkModal', // 展示remark 数据遮罩层在ppt上
  ENABLE_POINTER: 'enablePointer', // 是否允许打点
  SET_REMARK_LIST: 'setRemarkList', // 设置打点数据
  SET_ALL_REMARK_LIST: 'setAllRemarkList', // 设置打点数据
  UPDATE_LATEST_REMAK_ID: 'updateLatestRemarkId', // 更新id
  CHANGE_REMARK_INPUT_TYPE: 'changeRemarkInputType',
  CHANGE_REMARK_INDEX: 'changeRemarkIndex',
  ADD_ONE_REMARK_ITEM: 'addONERemarkItem',
  SET_IS_INPUTING: 'setIsInputing',
  SET_CURRENT_REMARK_OPTIONS: 'setCurrentRemarkOptions'
}
